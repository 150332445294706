// Types
import type { NavItem } from '@zola-helpers/client/dist/es/nav/types';
import type { SecondaryNavItem } from '@zola-helpers/client/dist/es/constants/navConstants';
import type { UserContext } from '@zola-helpers/client/dist/es/@types';

// Utils
import isClientSide from 'libs/client/utils/isClientSide';

type InitNavDataOptions = {
  activePrimaryLink?: string;
  activeSecondaryLink?: string;
  disablePrimaryNavCollapse: boolean;
  hideBanner?: boolean;
  quickLinks?: SecondaryNavItem[];
  secondaryData?: SecondaryNavItem[] | NavItem[];
  showPromo: boolean;
  tertiaryData?: SecondaryNavItem[] | NavItem[];
  userContext: UserContext | null;
};

const initNavData = ({
  activePrimaryLink,
  activeSecondaryLink,
  disablePrimaryNavCollapse,
  hideBanner,
  quickLinks,
  secondaryData,
  showPromo,
  tertiaryData,
  userContext,
}: InitNavDataOptions) => {
  if (isClientSide()) {
    window.dispatchEvent(
      new CustomEvent('INIT_NAV_DATA', {
        detail: {
          activePrimaryLink,
          activeSecondaryLink,
          disablePrimaryNavCollapse,
          hideBanner,
          quickLinks,
          secondaryData,
          showPromo,
          tertiaryData,
          userContext,
        },
      })
    );
  }
};

export default initNavData;
