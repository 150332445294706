import React, { useCallback, useEffect, useMemo } from 'react';
import Head from 'next/head';

// Components
import PreauthNavSkeletonLoader from '@zola/zola-ui/src/components/PreauthNavSkeletonLoader';

// Constants
import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';

// Types
import type { WWeddingShopNavView, WStoreNavView } from '@zola/svc-web-api-ts-client';
import type { UserContext } from '@zola-helpers/client/dist/es/@types';
import { SecondaryNavType } from 'components/Nav/types';

// Utils
import { getNavSource } from 'libs/client/nav';
import isClientSide from 'libs/client/utils/isClientSide';
import experimentFlags, {
  HOLIDAY_CARDS,
  HOLIDAY_SHOPS,
  NEW_PAPER_TYPE_NAPKINS,
  NEW_PAPER_TYPE_SIGNAGE,
  NEW_PAPER_TYPE_STICKERS,
} from 'libs/client/utils/experimentFlags';
import { useIsPostAuthNav3Mobile } from 'hooks/useIsPostAuthNav3Active';
import {
  getAlbumsNavItems,
  getAlbumsSecondaryNavItems,
} from '@zola-helpers/client/dist/es/nav/albumsNavData';
import { getBoutiqueNavItems } from '@zola-helpers/client/dist/es/nav/boutiqueNavData';
import { getHoneymoonsNavItems } from '@zola-helpers/client/dist/es/nav/honeymoonsNavData';
import { getGuestListSecondaryNavItems } from '@zola-helpers/client/dist/es/nav/guestListNavData';
import { getInvitesNavItems } from '@zola-helpers/client/dist/es/nav/invitesNavData';
import { getWebsiteSecondaryNavItems } from '@zola-helpers/client/dist/es/nav/websiteNavData';
import {
  getRegistryNavItems,
  getRegistrySecondaryNavItems,
  getRegistryQuickLinks,
} from '@zola-helpers/client/dist/es/nav/registryNavData';
import initNavData from './initNavData';

// Styles
import { NavContainer, RootNav } from './Nav.styles';

const {
  EVENTS: { NAV_LOADED },
} = NAV_DATA;

export type NavProps = {
  navHTML: string | null;
  shopCategories?: WStoreNavView;
  weddingShopCategories?: WWeddingShopNavView;
  user: UserContext | null;
  activePrimaryLink?: string;
  activeSecondaryLink?: string;
  showPromo?: boolean;
  disablePrimaryNavCollapse?: boolean;
  disableSecondary?: boolean;
  hideBanner?: boolean;
  className?: string;
  secondaryNavType?: SecondaryNavType;
  ssg?: boolean;
  ssr?: boolean;
};

const Nav: React.FC<NavProps> = ({
  navHTML,
  className,
  activePrimaryLink,
  showPromo = false,
  user = null,
  disablePrimaryNavCollapse = false,
  disableSecondary = false,
  hideBanner,
  activeSecondaryLink,
  shopCategories,
  weddingShopCategories,
  secondaryNavType,
  ssg = false,
  children,
  ssr = true,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}): JSX.Element => {
  const { navScript, navStyle } = getNavSource(navHTML, ssg);
  const isGuest = user ? Boolean(user.is_guest) : true;
  const isPostAuthNav3MwebEnabled = useIsPostAuthNav3Mobile(user);
  const showHolidayCards = isClientSide() ? experimentFlags.getFlag(HOLIDAY_CARDS, user) : false;
  const showHolidayShopNav = isClientSide() ? experimentFlags.getFlag(HOLIDAY_SHOPS, user) : false;
  const enableNapkins = isClientSide()
    ? experimentFlags.getFlag(NEW_PAPER_TYPE_NAPKINS, user)
    : false;
  const enableSignage = isClientSide()
    ? experimentFlags.getFlag(NEW_PAPER_TYPE_SIGNAGE, user)
    : false;
  const enableStickers = isClientSide()
    ? experimentFlags.getFlag(NEW_PAPER_TYPE_STICKERS, user)
    : false;

  const secondaryData = useMemo(() => {
    let data;
    if (disableSecondary) return data;
    switch (secondaryNavType) {
      case SecondaryNavType.GUEST_LIST:
        data = getGuestListSecondaryNavItems(user || undefined);
        break;
      case SecondaryNavType.PAPER:
        if (isGuest) {
          data = getInvitesNavItems({
            forTertiaryNav: false,
            hideNav: false,
            isMobile: isPostAuthNav3MwebEnabled,
            showHolidayCards,
            enabledNewPaper: {
              napkins: enableNapkins,
              signage: enableSignage,
              stickers: enableStickers,
            },
          });
        }
        break;
      case SecondaryNavType.WEBSITE:
        data = getWebsiteSecondaryNavItems(user);
        break;
      case SecondaryNavType.REGISTRY:
        data = isGuest
          ? getRegistryNavItems(user, shopCategories, showHolidayShopNav)
          : getRegistrySecondaryNavItems(user as UserContext);
        break;
      case SecondaryNavType.WEDDING_BOUTIQUE:
        data = getBoutiqueNavItems(user, weddingShopCategories);
        break;
      case SecondaryNavType.ALBUMS:
        data = !isGuest && user ? getAlbumsSecondaryNavItems(user) : getAlbumsNavItems(user);
        break;
      case SecondaryNavType.HONEYMOONS:
        data = getHoneymoonsNavItems();
        break;
      default:
    }
    return data;
  }, [
    disableSecondary,
    isGuest,
    isPostAuthNav3MwebEnabled,
    secondaryNavType,
    shopCategories,
    user,
    weddingShopCategories,
    showHolidayCards,
    showHolidayShopNav,
    enableNapkins,
    enableSignage,
    enableStickers,
  ]);

  const tertiaryData = useMemo(() => {
    let data;
    if (disableSecondary || isGuest) return data;
    switch (secondaryNavType) {
      case SecondaryNavType.PAPER:
        data = getInvitesNavItems({
          forTertiaryNav: true,
          hideNav: false,
          isMobile: isPostAuthNav3MwebEnabled,
          showHolidayCards,
          enabledNewPaper: {
            napkins: enableNapkins,
            signage: enableSignage,
            stickers: enableStickers,
          },
        });
        break;
      case SecondaryNavType.REGISTRY:
        data = getRegistryNavItems(user, shopCategories, showHolidayShopNav);
        break;
      default:
    }
    return data;
  }, [
    disableSecondary,
    isGuest,
    isPostAuthNav3MwebEnabled,
    secondaryNavType,
    shopCategories,
    user,
    showHolidayCards,
    showHolidayShopNav,
    enableNapkins,
    enableSignage,
    enableStickers,
  ]);

  const quickLinks = useMemo(() => {
    let data;
    if (!disableSecondary && secondaryNavType === SecondaryNavType.REGISTRY && !isGuest) {
      const registrySlug = user?.has_registry
        ? user?.user_role_account_weddings?.[0].slug
        : undefined;
      data = getRegistryQuickLinks(registrySlug);
    }
    return data;
  }, [
    disableSecondary,
    isGuest,
    secondaryNavType,
    user?.has_registry,
    user?.user_role_account_weddings,
  ]);

  const showAnnouncementBar = useMemo(
    () => showPromo || (!isGuest && !hideBanner),
    [hideBanner, isGuest, showPromo]
  );
  const showSecondaryData = useMemo(
    () => (secondaryData ? secondaryData.length > 0 : false),
    [secondaryData]
  );
  const showTertiaryData = useMemo(
    () => (tertiaryData ? tertiaryData.length > 0 : false),
    [tertiaryData]
  );

  const handleInitNavData = useCallback(() => {
    initNavData({
      activePrimaryLink,
      activeSecondaryLink,
      disablePrimaryNavCollapse,
      hideBanner,
      quickLinks,
      secondaryData,
      showPromo,
      tertiaryData,
      userContext: user,
    });
  }, [
    activePrimaryLink,
    activeSecondaryLink,
    disablePrimaryNavCollapse,
    hideBanner,
    quickLinks,
    secondaryData,
    showPromo,
    tertiaryData,
    user,
  ]);

  useEffect(() => {
    if (isClientSide()) {
      window.addEventListener(NAV_LOADED, handleInitNavData);
    }
    return () => {
      window.removeEventListener(NAV_LOADED, handleInitNavData);
    };
  }, [handleInitNavData]);

  // If data changes after the nav initialization, update the nav
  useEffect(() => {
    if (user) {
      handleInitNavData();
    }
  }, [handleInitNavData, user]);

  return (
    <>
      {!ssr && (
        <Head>
          <link rel="stylesheet" href={navStyle} />
          <script src={navScript} />
        </Head>
      )}
      <NavContainer
        id="navContainerSSG"
        className={className}
        isGuest={isGuest}
        showAnnouncementBar={showAnnouncementBar}
        showSecondaryData={showSecondaryData}
        showTertiaryData={showTertiaryData}
      >
        {/* web-preauth only extract style and javascript files. */}
        {/* the following DOM structure should follow what's in the index.html of web-nav */}
        {ssr && navHTML && (
          <RootNav
            id="root-nav-container"
            data-testid="root-nav"
            dangerouslySetInnerHTML={{ __html: navHTML }}
          />
        )}
        {!ssr && (
          <RootNav id="root-nav" data-testid="root-nav">
            <div key="PreauthNavSkeletonLoader">
              <PreauthNavSkeletonLoader
                showPromoBar={showPromo}
                showSecondaryRow={!disableSecondary}
              />
            </div>
          </RootNav>
        )}
        {children}
        <div id="notification-root" data-testid="notification-root" />
      </NavContainer>
    </>
  );
};

export default Nav;
